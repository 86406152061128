<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

// import VueVideoPlayer from 'vue-video-player'
import LightBox from 'vue-it-bigger'
require('vue-it-bigger/dist/vue-it-bigger.min.css')
import { getEventsApi } from '@/api/events'


/**
 * Gallery component
 */
export default {
  page: {
    title: "Activity",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      lightboximgs: [
      ],
      projectGrid: [],
      title: "Activity",
      items: [
      ],
      media: [],
      filterCategory: "all",
      visible: false,
      index: 0,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
    };
  },
  components: {
    Layout,
    PageHeader,
    LightBox
   // VueVideoPlayer
    
  },
  methods: {
    listCategory(value) {
      this.filterCategory = value;
    },
    showImg(index) {
     // this.index = index;
   
     this.$refs['videoBox'].showImage(index)
      this.visible = true;
    },

    pageChange(p) {
      this.currentPage = p;
      this.loadTraningList();
    },

    edit_activity(activity) { 
      
      this.$router.push({name : 'events-activity-edit', params: {activity: activity}})

    },

    delete_activity(activity) {
      console.log(activity)
      let data = {
        evt_id : activity.id
      }
      getEventsApi().delete_event(data).then((res) => {
        if (res.errCode == 0) {
            this.$alertify.message("Delete Success");
            this.projectGrid = this.projectGrid.filter((e) => e.id != activity.id)
          } else {
            this.$alertify.error("Delete Failed " + res.errCode);
          }
      })

      
    },

    loadActivityList() {
      let data = {
        page: {
          page: this.currentPage,
          page_size: this.perPage
        }
      }
      getEventsApi().activity_list(data).then((res) => {
        if (res.errCode == 0) {
          this.projectGrid = []
          this.lightboximgs = []
          res.events.map((e) => {
            this.projectGrid.push(
              {
                image: e.default_photo_url? e.default_photo_url : require("@/assets/images/small/img-1.jpg"),
                title: e.title,
                start_time: e.start_time,
                category: 'designing',
                address: e.address,
                city: e.city,
                id : e.id,
                end_time : e.end_time,
                description: e.description
              }
            )

            let video_source =[]
            if (e.attachments) {
              let video_url = e.attachments.find((v) => v.attachment_type == 3)
              if (video_url) {
                video_source.push({
                  src : video_url.attachment_url,
                  type: 'video/mp4'
                })
              } else {
                video_source.push({
                  src : 'https://www.w3schools.com/html/mov_bbb.mp4',
                  type: 'video/mp4'
                })
                
              }
            }
            this.media.push( {
                type: 'video',
                thumb:  e.default_photo_url? e.default_photo_url : require("@/assets/images/small/img-1.jpg"),
                sources: video_source,
                caption: '<h4>Monsters Inc.</h4>',
                width: 800, // Required
                height: 600, // Required
                autoplay: false // Optional: Autoplay video when the lightbox opens
              })
            this.lightboximgs.push(e.default_photo_url)
          })

          this.totalRows = res.page.total
        }

      })

    }
  },
  computed: {
    filteredData: function () {
      if (this.filterCategory === "all") {
        return this.projectGrid;
      } else {
        return this.projectGrid.filter(
          (x) => x.category === this.filterCategory
        );
      }
    },
  },

  mounted() {
    this.loadActivityList()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <!-- end card header -->
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="col-auto">
                  <div class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3">


                    <b-dropdown variant="link" toggle-class="shadow-none text-dark" right>
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <li><b-dropdown-item>
                          <router-link :to="{ name: 'events-activity-edit' }" class="text-body fw-medium"> Create New
                            Activity</router-link></b-dropdown-item></li>

                    </b-dropdown>
                  </div>
                </div>

                <div class="row gallery-wrapper">
                  <div v-for="(item, index) in filteredData" :key="index" class="col-xl-4 col-sm-6">
                    <div class="gallery-box card">
                      <div class="row">

                        <div class="d-flex flex-wrap align-items-start justify-content-md-end gap-1 mb-1">


                          <b-dropdown variant="link" toggle-class="shadow-none text-dark" right>
                            <template v-slot:button-content>
                              <i class="uil uil-ellipsis-h"></i>
                            </template>
                            <li><b-dropdown-item @click="edit_activity(item)">
                                
                                  Edit</b-dropdown-item></li>
                              <li><b-dropdown-item @click="delete_activity(item)">
                              delete</b-dropdown-item></li>

                          </b-dropdown>
                        </div>
                      </div>

                      <div class="gallery-container">
                        <a class="image-popup" href="javascript:void(0);" @click="() => showImg(index)">
                          <img class="gallery-img img-fluid mx-auto" :src="`${item.image}`" alt="" />
                          <div class="gallery-overlay"></div>
                        </a>
                      </div>

                      <div class="box-content p-3">
                        <h5 class="title">{{ item.title }}</h5>
                        <p class="post">
                          Address: {{ item.address? item.address : 'N/A' }}
                        </p>
                        <p class="post">
                          {{ item.start_time }} - {{ item.end_time }}
                        </p>
                       
                      </div>


                    </div>
                  </div>
                </div>
                <!-- end row -->
                <LightBox ref="videoBox" :showLightBox="false" :show-caption="true" :media="media"  :showThumbs="true" :autoPlay="false"></LightBox>
              </div>
            </div>
            <!-- end row -->


            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-7">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

          </div>
          <!-- ene card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
